import {
    Container,
    Grid,
    LinearProgress,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow
} from "@material-ui/core";
import React, {useState} from "react";
import {useStyles} from "./style";
import {useTheme} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import Alert from "@material-ui/lab/Alert";
import {GridRowsProp} from '@material-ui/data-grid';
import {PurchaseService} from "../../../services/purchase.service";
import {UserService} from "../../../services/user.service";
import {formatDateToStringDate} from "../../../utils/date.util";
import {CatalogueTableModel, PurchaseCatalogueModel} from "../../../models/catalogue.model";
import PageTitle from "../../Common/PageTitle";


export default function EnterprisePurchase() {
    const theme = useTheme();
    const classes = useStyles(theme);
    const {t} = useTranslation();
    const user = (new UserService()).getCurrentUser();

    const [page, setPage] = React.useState(0);
    const [rowCount, setRowCount] = React.useState(0);
    const [rows, setRows] = React.useState<GridRowsProp>([]);
    const [errorMessage, setErrorMessage] = useState("");

    const [loading, setLoading] = React.useState<boolean>(false);
    const purchaseService = new PurchaseService();


    const handlePageChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage);
    };

    const labelDisplayedRows = ({from, to, count}: { from: number, to: number, count: number }) => {
        // const test = count !== -1 ? count : 'more than';
        // return `${from}-${to} of ${test} ${to}`
        const currentPage = page + 1;
        const pageCount = Math.ceil(count / PurchaseService.defaultPageSize);
        const total = pageCount < 1 ? 1 : pageCount;
        return t('pagination', {current: currentPage, total: total});
    };

    const mapDataToRowModel = (purchases: PurchaseCatalogueModel[]) => {
        const res = purchases.map((purchase, index) => {
                return {
                    id: index,
                    date: purchase.date,
                    amount: purchase.amount,
                    nbUsers: purchase.emails?.length,
                    emails: purchase.emails.toString(),
                } as CatalogueTableModel
            }
        );
        return res;
    }

    React.useEffect(() => {
            let active = true;
            (async () => {
                setLoading(true);
                const pageToFetch = (page < 0) ? 0 : page;
                const {total, data} = await purchaseService.findByUserID(user.userID, pageToFetch)
                    .then((res) => {
                        setLoading(false);
                        return res;
                    })
                    .catch(() => {
                        setLoading(false);
                        setErrorMessage(t('enterprise.dashboard.purchases.unexpectedError'))
                        return {total: 0, data: []};
                    });
                if (!active) {
                    return;
                }
                setRows(mapDataToRowModel(data));
                setRowCount(total);
                setLoading(false);
            })();
            return () => {
                active = false;
            };
        },
        // eslint-disable-next-line
        [page]);

    return (<Container maxWidth="lg" className={classes.root}>

            <PageTitle title={t("menu.myPurchases")}/>

            <Grid container className={classes.row} direction="row"
                  justify={'center'} alignItems={'stretch'}>
                {errorMessage && <Alert severity="error" className={classes.row}>{errorMessage}</Alert>}

                <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="custom pagination table">
                        <TableHead>
                            <TableRow>
                                <TableCell>{t('enterprise.dashboard.purchases.date')}</TableCell>
                                <TableCell>{t('enterprise.dashboard.purchases.montant')}</TableCell>
                                <TableCell>{t('enterprise.dashboard.purchases.nbUsers')}</TableCell>
                                <TableCell>{t('enterprise.dashboard.purchases.emails')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {loading && <TableRow key={'progress'} className={classes.rowProgress}>
                                <TableCell colSpan={4}><LinearProgress/></TableCell>
                            </TableRow>}
                            {(rows).map((row) => (
                                <TableRow key={row.id}>
                                    <TableCell component="th" scope="row">
                                        {formatDateToStringDate(row.date)}
                                    </TableCell>
                                    <TableCell>
                                        {row.amount} &euro;
                                    </TableCell>
                                    <TableCell>
                                        {row.nbUsers}
                                    </TableCell>
                                    <TableCell>
                                        {row.emails}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    colSpan={3}
                                    count={+rowCount}
                                    page={page}
                                    onChangePage={handlePageChange}
                                    rowsPerPage={+PurchaseService.defaultPageSize}
                                    rowsPerPageOptions={[]}
                                    labelDisplayedRows={labelDisplayedRows}/>
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </Grid>

        </Container>
    );
}
