import {Container, Grid} from "@material-ui/core";
import React from "react";
import {useStyles} from "./style";
import {AccountCircleOutlined} from "@material-ui/icons";
import {useTheme} from "@material-ui/core/styles";
import DashboardUserContentPaper from "../DashboardLearnerContentPaper";
import InsertChartOutlinedIcon from '@material-ui/icons/InsertChartOutlined';
import NotificationsOutlinedIcon from '@material-ui/icons/NotificationsOutlined';
import AttachFileOutlinedIcon from '@material-ui/icons/AttachFileOutlined';
import PeopleAltOutlinedIcon from '@material-ui/icons/PeopleAltOutlined';
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router";
import FlagOutlinedIcon from '@material-ui/icons/FlagOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import ContactUsBox from "../../Common/ContactUsBox";
import WelcomeMessage from "../WelcomeMessage";
import WelcomeVideo from "../../Common/WelcomeVideo";
import MyCoachListFooter from "../MyCoachListFooter";
import FormatListBulletedOutlinedIcon from '@material-ui/icons/FormatListBulletedOutlined';

export default function DashboardLearnerContent() {
    const theme = useTheme();
    const classes = useStyles(theme);
    const {t} = useTranslation();
    const navigate = useNavigate();

    const profile = () => {
        navigate('/learner/profile');
    }
    const parcours = () => {
        navigate('/learner/parcours');
    }

    const progression = () => {
        navigate('/learner/progression');
    }

    const settings = () => {
        navigate('/learner/settings');
    }

    const documents = () => {
        navigate('/learner/documents');
    }

    const notifications = () => {
        navigate('/learner/notifications');
    }

    const coachs = () => {
        navigate('/learner/coachs');
    }
    const request = () => {
        navigate('/learner/requests');
    }

    return (<Container maxWidth="lg" className={classes.root}>
        <WelcomeMessage showTitle={true}/>
        <WelcomeVideo/>

        <Grid container spacing={3} className={classes.row} direction="row"
              justify={'center'} alignItems={'stretch'}>
            <Grid item xs={12} md={6} lg={4} className={classes.column} onClick={parcours}>
                <DashboardUserContentPaper icon={() => <FlagOutlinedIcon/>}
                                           menuTitle={t('menu.parcoursTitle')}
                                           menuDescription={t('menu.parcours')}/>
            </Grid>
            <Grid item xs={12} md={6} lg={4} className={classes.column} onClick={profile}>
                <DashboardUserContentPaper icon={() => <AccountCircleOutlined/>}
                                           menuTitle={t('menu.infosTitle')}
                                           menuDescription={t('menu.infos')}/>
            </Grid>
            <Grid item xs={12} md={6} lg={4} className={classes.column} onClick={settings}>
                <DashboardUserContentPaper icon={() => <SettingsOutlinedIcon/>}
                                           menuTitle={t('menu.settingsTitle')}
                                           menuDescription={t('menu.settings')}/>
            </Grid>
            <Grid item xs={12} md={6} lg={4} className={classes.column} onClick={progression}>
                <DashboardUserContentPaper icon={() => <InsertChartOutlinedIcon/>}
                                           menuTitle={t('menu.consosTitle')}
                                           menuDescription={t('menu.consos')}/>
            </Grid>
            <Grid item xs={12} md={6} lg={4} className={classes.column} onClick={notifications}>
                <DashboardUserContentPaper icon={() => <NotificationsOutlinedIcon/>}
                                           menuTitle={t('menu.notifsTitle')}
                                           menuDescription={t('menu.notifs')}/>
            </Grid>
            <Grid item xs={12} md={6} lg={4} className={classes.column} onClick={documents}>
                <DashboardUserContentPaper icon={() => <AttachFileOutlinedIcon/>}
                                           menuTitle={t('menu.docsTitle')}
                                           menuDescription={t('menu.docs')}/>
            </Grid>
            <Grid item xs={12} md={6} lg={4} className={classes.column} onClick={coachs}>
                <DashboardUserContentPaper icon={() => <PeopleAltOutlinedIcon/>}
                                           menuTitle={t('menu.coachsTitle')}
                                           menuDescription={t('menu.coachs')}/>
            </Grid>
            <Grid item xs={12} md={6} lg={4} className={classes.column} onClick={request}>
                <DashboardUserContentPaper icon={() => <FormatListBulletedOutlinedIcon/>}
                                           menuTitle={t('menu.requestsTitle')}
                                           menuDescription={t('menu.requests')}/>
            </Grid>
            <ContactUsBox/>
        </Grid>

        <MyCoachListFooter/>

    </Container>);
}