import {useLocation, useNavigate} from "react-router";
import {Button} from "@material-ui/core";
import React from "react";
import {ArrowBack} from "@material-ui/icons";
import {useTheme} from "@material-ui/core/styles";
import {useStyles} from "./style";
import {ADD_USERS_ROUTE_PATH, BILAN_PRO_ROUTE_PATH, PROFILE_ROUTE_PATH} from "../../../utils/router.util";

export interface GoBackButtonProps {
    goToParcours?: boolean
}

export default function GoBackButton(props: GoBackButtonProps) {
    const theme = useTheme();
    const classes = useStyles(theme);

    const navigate = useNavigate();
    const location = useLocation();

    const goBack = () => {
        if (location?.pathname.endsWith(PROFILE_ROUTE_PATH) || location?.pathname.endsWith(ADD_USERS_ROUTE_PATH)) {
            /*
            Return to the home page if it's the profile page. Added this patch in case of first connection after password change.
            Return to the home page if it's the ADD USER page. Added this patch in case of going back after stripe payment.
             */
            // TODO add a props "goToHome" with default false
            navigate('/');
        } else if (location?.pathname.endsWith(BILAN_PRO_ROUTE_PATH)) {
            navigate('../../parcours');
        } else if (props.goToParcours) {
            navigate('../parcours');
        } else {
            navigate(-1);
        }
    }

    return (
        <Button variant="text" onClick={() => goBack()} endIcon={<ArrowBack className={classes.icon}/>}
                className={classes.root}>
        </Button>
    );
}