import React, {useEffect} from 'react';
import {createMuiTheme, ThemeProvider, useTheme} from '@material-ui/core/styles';
import {useStyles} from "./style";
import {CssBaseline, SwipeableDrawer} from "@material-ui/core";
import SliderMenuContent from "../../components/Learner/SliderMenuContent";
import {RouterContainer} from "../../container/RouterContainer";
import {Outlet} from "react-router-dom";
import {SuiviConsommationContainer} from '../../container/SuiviConsommationContainer';
import {UserService} from "../../services/user.service";
import {SettingsContainer} from "../../container/SettingsContainer";
import AppTopBar from "../../components/Common/AppTopBar";
import {UserDocumentsContainer} from "../../container/UserDocumentContainer";
import CVNotLoaded from "../../components/Learner/CVNotLoaded";
import {CV_KEY} from "../../utils/userDocs.utils";

export const dashboardTheme = createMuiTheme({
    overrides: {
        // Style sheet name ⚛️
        MuiTypography: {
            // Name of the rule
            subtitle2: {
                // Some CSS
                color: '#f44336',
            },
        },
    },
    palette: {
        primary: {
            main: '#311b92',
        },
        secondary: {
            main: '#f44336'
        },
    },
});
export default function DashboardLearner() {
    const theme = useTheme();
    const classes = useStyles(theme);
    const [open, setOpen] = React.useState(false);

    const routerState = RouterContainer.useContainer();
    const userService = new UserService();
    const suiviConsoState = SuiviConsommationContainer.useContainer();
    const settingsContainer = SettingsContainer.useContainer();
    const userDocumentState = UserDocumentsContainer.useContainer();

    useEffect(() => {
        //log.debug("Setting last asked url: " + location.pathname);
        routerState.setLastAskedUrl("");
        settingsContainer.initBackgroundColor();
        settingsContainer.initPrimaryColor();
    }, []);


    useEffect(() => {
            (async () => {
                const user = userService.getCurrentUser();
                suiviConsoState.loadSuiviConsommationsAndParcours(user.userID);
                suiviConsoState.loadHiddenModules();
                userDocumentState.loadUserDocument(user.userID, CV_KEY)
            })();
        }
        // eslint-disable-next-line
        , []);

    let dashboardContentTheme = createMuiTheme({
        overrides: {
            // Style sheet name ⚛️
            MuiTypography: {
                // Name of the rule
                subtitle2: {
                    // Some CSS
                    color: '#f44336',
                },
            },
            MuiTableCell: {
                head: {
                    color: "#311b92",
                }
            }
        },
        palette: {
            primary: {
                main: settingsContainer.primary,
            },
            background: {
                default: settingsContainer.background
            },
        },
    });

    const toggleDrawer = (open: boolean) => (
        event: React.KeyboardEvent | React.MouseEvent,
    ) => {
        if (
            event &&
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' ||
                (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return; // Ignore Tab and Shift keys
        }
        setOpen(open);
    };

    return (
        <ThemeProvider theme={dashboardTheme}>
            <CssBaseline/>

            <div className={classes.root}>
                <AppTopBar open={open} toggleDrawer={toggleDrawer}/>
                <main className={classes.content}>
                    <div className={classes.drawerHeader}/>
                    <CVNotLoaded/>
                    <ThemeProvider theme={dashboardContentTheme}>
                        <CssBaseline/>
                        <Outlet/>
                    </ThemeProvider>
                </main>
                <React.Fragment>
                    <SwipeableDrawer
                        anchor={'right'}
                        open={open}
                        onClose={toggleDrawer(false)}
                        onOpen={toggleDrawer(true)}
                        className={classes.drawer}
                    >
                        <SliderMenuContent toggleDrawer={toggleDrawer}/>
                    </SwipeableDrawer>
                </React.Fragment>
            </div>
        </ThemeProvider>
    );
}
