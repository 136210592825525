export interface CatalogueTableModel {
    emails: string;
    date: Date;
    amount: number;
    nbUsers: number;
}

export interface PurchaseCatalogueModel {
    date: Date;
    userID: string;
    amount: number;
    emails: string[];
    catalogue: any[];
    id: number;
}

export interface Catalogue {
    phase: PhaseEnum;
    service: ServiceEnum;
    heures: number;
    prix: number;
    hideInModuleList: false;
}

export interface PhaseService {
    phase: PhaseEnum;
    service: ServiceEnum;
    hideInModuleList: boolean;
}


export enum PhaseEnum {
    INTROSPECTION = 'INTROSPECTION',
    OPTIMIZE_IMAGE_PRO = 'OPTIMIZE_IMAGE_PRO',
    IDENTIFY_AND_TAKE_OPPORTUNITIES = 'IDENTIFY_AND_TAKE_OPPORTUNITIES',
    BRIEF = 'BRIEF',
    DEBRIEF = 'DEBRIEF',
}

export enum ServiceEnum {
    UNDERSTAND_MY_PERSONALITY = 'UNDERSTAND_MY_PERSONALITY',
    BILAN_PRO = 'BILAN_PRO',
    RESILIENCE = 'RESILIENCE',
    CV = 'CV', WELL_BEING = 'WELL_BEING',
    SELF_CONFIANCE = 'SELF_CONFIANCE',
    DIGITAL_IMAGE = 'DIGITAL_IMAGE',
    PITCH = 'PITCH',
    DIVERSITY = 'DIVERSITY',
    /*
        PLAN_ACTION = 'PLAN_ACTION',
    */
    NETWORKING = 'NETWORKING',
    TRAINING_AND_DEBRIEF = 'TRAINING_AND_DEBRIEF',
    DEVELOP_COMMUNICATION = 'DEVELOP_COMMUNICATION',
    BRIEF_OLIVIER = 'BRIEF_OLIVIER',
    DEBRIEF_OLIVIER = 'DEBRIEF_OLIVIER',
    BRIEF_LUDIVINE = 'BRIEF_LUDIVINE',
    DEBRIEF_LUDIVINE = 'DEBRIEF_LUDIVINE',
    VISION_360 = 'VISION_360',
    RAYONNER = 'RAYONNER',
}

export enum ParcoursEnum {
    CONSCIENCE_DE_SOI = "CONSCIENCE_DE_SOI",
    DEVELOPER_EMPLOYABILITY = "DEVELOPER_EMPLOYABILITY",
    OUTPLACEMENT = "OUTPLACEMENT"
}

export enum ModulesFilterEnum {
    MY_MODULES = 'MY_MODULES',
    OTHER_MODULES = 'OTHER_MODULES',
}