import {
    Badge,
    Button,
    ClickAwayListener,
    Divider,
    Fade,
    IconButton,
    Paper,
    Popper,
    Typography
} from "@material-ui/core";
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import {useTheme} from "@material-ui/core/styles";
import {useStyles} from "./style";
import React, {useEffect, useState} from "react";
import {UserService} from "../../../services/user.service";
import {NotificationModel, NotificationModelPaginate, NotificationTypeEnum} from "../../../models/notification.model";
import Alert from "@material-ui/lab/Alert";
import {useTranslation} from "react-i18next";
import {getFullname} from "../../../utils/user.utils";
import {useNavigate} from "react-router";
import {ServiceEnum} from "../../../models/catalogue.model";
import BybButton from "../BybButton";
import clsx from "clsx";
import {NotificationContainer} from "../../../container/NotificationContainer";

export function NotificationBadge() {
    const theme = useTheme();
    const classes = useStyles(theme);
    const userService = new UserService();
    const userID = userService.getCurrentUser().userID

    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [open, setOpen] = React.useState(false);
    const [hasMoreNotifications, setHasMoreNotifications] = React.useState(true);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [numberOfNotification, setNumberOfNotification] = React.useState(0);
    const notificationState = NotificationContainer.useContainer();

    const {t} = useTranslation();
    const navigate = useNavigate();

    const UPDATE_INTERVAL = 4 * 60 * 60 * 1000; // 4 heures

    const showPopover = (event: any) => {
        setOpen(!open);
        setAnchorEl(event.currentTarget);
    }

    const getServiceName = (service: ServiceEnum): string => {
        const nameAndDesc = t(`catalogue.services.learner.${service}`);
        return nameAndDesc.split(':')?.[0]?.trim();
    }

    const getNotificationText = (notification: NotificationModel): string => {
        if (notification.type === NotificationTypeEnum.SESSION_CONFIRM) {
            return t(`notification.${notification.type}`, {
                service: getServiceName(notification.service),
                coach: getFullname(notification.fromUser?.firstname, notification.fromUser?.lastname),
                session: notification.session
            });
        } else if (notification.type === NotificationTypeEnum.REQUEST_MODULE) {
            return t(`notification.${notification.type}`, {
                service: getServiceName(notification.service),
                user: getFullname(notification.fromUser?.firstname, notification.fromUser?.lastname),
            });
        }
        return "";
    }

    const handleClick = (notification: NotificationModel) => async (event: any) => {
        if (notification.type === NotificationTypeEnum.SESSION_CONFIRM) {
            //await notificationState.read(notification);
            navigate("/learner/notifications");
            setOpen(false);
        } else if (notification.type === NotificationTypeEnum.REQUEST_MODULE) {
            await notificationState.read(notification);
            navigate("/enterprise/notifications");
            setOpen(false);
        }
    }

    const handleClickAway = () => {
        setOpen(false);
    }

    const handleLoadMore = () => {
        notificationState.setPage(notificationState.page + 1);
        fetchNotifications();
    }

    const fetchNotifications = async () => {
        if (userID) {
            notificationState.loadUnreadNotifs(notificationState.page).then((notifPaginate: NotificationModelPaginate) => {
                if (notificationState.getUnreadNotifs().length < notifPaginate.total) {
                    setHasMoreNotifications(true);
                } else {
                    setHasMoreNotifications(false);
                }
            }).catch(() => {
                setErrorMessage(t('unexpectedErrorLoading'));
            });
        }

    };

    useEffect(() => {
        fetchNotifications();
        const interval = setInterval(fetchNotifications, UPDATE_INTERVAL);
        return () => clearInterval(interval);
    }, [userID, notificationState.page]);


    useEffect(() => {
        // Number of notification to display in the badge bell
        let nb = notificationState.getUnreadNotifs().map(n => !n.read).length;
        if (nb === 5 && hasMoreNotifications) {
            nb++;
        }
        return setNumberOfNotification(nb);
    }, [notificationState.getUnreadNotifs(), hasMoreNotifications]);


    const notificationsListComponent = notificationState.getUnreadNotifs().map((value: NotificationModel, index: number) => (
        <div key={"notif" + index}>
            <Button className={classes.notifWrapper}
                    onClick={handleClick(value)}>
                <Typography variant={"subtitle1"} dangerouslySetInnerHTML={{__html: getNotificationText(value)}}>
                </Typography>
            </Button>
            <Divider/>
        </div>
    ));


    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <div>
                {notificationState.getUnreadNotifs().length > 0 &&
                    <IconButton aria-label="notification" onClick={showPopover}
                                className={clsx(open && classes.notificationActivated)}>
                        <Badge badgeContent={numberOfNotification} color="secondary"
                               max={5} className={classes.notification}>
                            <NotificationsNoneIcon className={classes.notificationIcon}/>
                        </Badge>
                    </IconButton>}
                <Popper open={open} anchorEl={anchorEl} placement="bottom" transition className={classes.popper}>
                    <Fade timeout={350}>
                        <>
                            {errorMessage && <Alert severity="error" className={classes.row}>{errorMessage}</Alert>}
                            <Paper className={classes.paper}>
                                {notificationsListComponent}
                                {hasMoreNotifications && <BybButton classes={classes.button}
                                                                    loading={isLoading}
                                                                    label={t('showMore')}
                                                                    color="primary"
                                                                    variant="contained"
                                                                    onClick={handleLoadMore}>
                                </BybButton>}
                            </Paper>
                        </>
                    </Fade>
                </Popper>
            </div>
        </ClickAwayListener>
    );

}
