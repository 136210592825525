import {
    Container,
    Grid,
    LinearProgress,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow
} from "@material-ui/core";
import React, {useState} from "react";
import {useStyles} from "./style";
import {useTheme} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import Alert from "@material-ui/lab/Alert";
import {PurchaseService} from "../../../services/purchase.service";
import PageTitle from "../../Common/PageTitle";
import {RequestModuleService} from "../../../services/request-module.service";
import {GridRowsProp} from "@material-ui/data-grid";
import {nanoid} from "nanoid";
import {EnterpriseService} from "../../../services/enterprise.service";
import {formatDateToStringDate} from "../../../utils/date.util";
import {RequestModule, RequestModuleStatusEnum} from "../../../models/request-module";
import {getFullnameOrEmailForUser} from "../../../utils/user.utils";

export default function EnterpriseLearnersRequests() {
    const theme = useTheme();
    const classes = useStyles(theme);
    const {t} = useTranslation();

    const [errorMessage, setErrorMessage] = useState("");
    const [loading, setLoading] = React.useState<boolean>(false);
    const [page, setPage] = React.useState(0);
    const [rowCount, setRowCount] = React.useState(0);
    const [rows, setRows] = React.useState<GridRowsProp>([]);
    const [isValidating, setIsValidating] = React.useState<boolean>(false);

    const requestModuleService = new RequestModuleService();

    const handlePageChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage);
    };

    const labelDisplayedRows = ({from, to, count}: { from: number, to: number, count: number }) => {
        const currentPage = page + 1;
        const pageCount = Math.ceil(count / EnterpriseService.defaultPageSize);
        const total = pageCount < 1 ? 1 : pageCount;
        return t('pagination', {current: currentPage, total: total});
    };

    const serviceTitle = (service: string): string => {
        return t(`catalogue.services.${service}`).split(':')?.[0].trim()
            || t(`catalogue.services.${service}`)
    };
    const phaseTitle = (phase: string): string => {
        return t(`catalogue.phases.${phase}`).split(':')?.[0].trim()
            || t(`catalogue.phases.${phase}`)
    };

    const getStatus = (status: RequestModuleStatusEnum): string => {
        return status == RequestModuleStatusEnum.PENDING ?
            t('learner.requestsModule.pending') :
            t('learner.requestsModule.validated');
    };
    const confirmSessionRequest = (request: RequestModule): void => {
        setIsValidating(true);
        // TODO appeler le back pour initier un flow de paiement stripe
    };

    React.useEffect(() => {
            (async () => {
                setLoading(true);
                const pageToFetch = (page < 0) ? 0 : page;
                const {
                    total,
                    data
                } = await requestModuleService.getAll(pageToFetch)
                    .then((res) => {
                        return res;
                    })
                    .catch(() => {
                        setErrorMessage(t('unexpectedErrorLoading'))
                        return {total: 0, data: []};
                    })
                    .finally(() => {
                        setLoading(false);
                    });
                setRows(data);
                setRowCount(total);
            })();
        },
        // eslint-disable-next-line
        [page]);


    return (<Container maxWidth="lg" className={classes.root}>
        {errorMessage && <Alert severity="error" className={classes.row}>{errorMessage}</Alert>}

        <PageTitle title={t("menu.notifsTitle")}/>
        <Grid container className={classes.row} direction="row"
              justify={'center'} alignItems={'stretch'}>
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="custom pagination table">
                    <TableHead>
                        <TableRow>
                            <TableCell>{t('learner.requestsModule.service')}</TableCell>
                            <TableCell>{t('learner.requestsModule.description')}</TableCell>
                            <TableCell>{t('learner.requestsModule.date')}</TableCell>
                            <TableCell>{t('learner.requestsModule.user')}</TableCell>
                            {/*
                            <TableCell>{t('learner.requestsModule.action')}</TableCell>
*/}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading && <TableRow key={nanoid()} className={classes.rowProgress}>
                            <TableCell colSpan={5}><LinearProgress/></TableCell>
                        </TableRow>}
                        {(rows).map((row) => (
                            <TableRow key={row.id}>
                                <TableCell>
                                    {serviceTitle(row.service)}
                                </TableCell>
                                <TableCell>
                                    {t(`catalogue.services.learner.${row.service}`)}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {formatDateToStringDate(row.createdDate)}
                                </TableCell>
                                <TableCell>
                                    {getFullnameOrEmailForUser(row.user)}
                                </TableCell>
                                {/* <TableCell>
                                    {row.status === RequestModuleStatusEnum.PENDING &&
                                        <BybButton
                                            onClick={() => confirmSessionRequest(row as RequestModule)}
                                            classes={classes.button}
                                            loading={isValidating}
                                            label={t('learner.requestsModule.validate')}
                                            color="primary"
                                            variant="outlined"
                                            type="button">
                                        </BybButton>
                                    }
                                </TableCell>*/}
                            </TableRow>))}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                colSpan={3}
                                count={+rowCount}
                                page={page}
                                onChangePage={handlePageChange}
                                rowsPerPage={+PurchaseService.defaultPageSize}
                                rowsPerPageOptions={[]}
                                labelDisplayedRows={labelDisplayedRows}/>
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </Grid>

    </Container>);
}
