import {Container, Grid, MenuItem, Select} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {useStyles} from "./style";
import {useTheme} from "@material-ui/core/styles";
import {SuiviConsommationContainer} from "../../../container/SuiviConsommationContainer";
import LearnerPhaseServiceList from "../LearnerPhaseServiceList";
import {ModulesFilterEnum, PhaseEnum} from "../../../models/catalogue.model";
import {
    ServicesByPhase,
    SuiviConsommationModel,
    SuiviConsommationsByPhase
} from "../../../models/suivi-consommation.model";
import WelcomeMessage from "../WelcomeMessage";
import {sortPhase} from "../../../utils/suiviConso.util";
import {useTranslation} from "react-i18next";
import _ from "lodash";
import {SuiviConsommationService} from "../../../services/suivi-consommation.service";
import {UserService} from "../../../services/user.service";


export default function LearnerPhaseServiceListWrapper() {
    const theme = useTheme();
    const classes = useStyles(theme);
    const suiviConsoState = SuiviConsommationContainer.useContainer();
    const {t} = useTranslation();
    const suiviConsoService = new SuiviConsommationService();
    const userService = new UserService();


    const suiviConsoByPhase: SuiviConsommationsByPhase = suiviConsoState.suiviConsoByPhase || {};
    const suiviConsos: SuiviConsommationModel[] = suiviConsoState.suiviConso;
    const allHiddenModulesByPhases: ServicesByPhase = suiviConsoState.hiddenModulesByPhase || {};
    const [allServicesByPhaseFiltered, setAllServicesByPhaseFiltered] = useState(
        suiviConsoState.suiviConsoByPhase as unknown as ServicesByPhase || {}
    );
    const [allPhasesFiltered, setAllPhasesFiltered] = useState([] as PhaseEnum[]);
    const [selectedFilter, setSelectedFilter] = useState<ModulesFilterEnum>(ModulesFilterEnum.MY_MODULES);


    const subtitle = t('welcomeMessage.subtitle', {parcoursName: suiviConsoState.userParcours?.name})

    const getPhasesFilteredList = (): PhaseEnum[] => {
        return sortPhase(allPhasesFiltered);
    }

    useEffect(() => {
        // Initially, we only display the Phase and Services that the user have in his parcours
        setAllServicesByPhaseFiltered(suiviConsoState.suiviConsoByPhase);
        setAllPhasesFiltered(Object.keys(suiviConsoByPhase) as PhaseEnum[]);
        setSelectedFilter(ModulesFilterEnum.MY_MODULES);
    }, [suiviConsoState.suiviConsoByPhase]);

    const modulesFilterEnum = {
        OTHER_MODULES: t('catalogue.otherModules'),
        MY_MODULES: t('catalogue.myModules'),
    }

    const filterModulesToDisplay = (filterUnit: ModulesFilterEnum) => {
        if (filterUnit === ModulesFilterEnum.OTHER_MODULES) {
            let otherModules: ServicesByPhase = {};
            let filteredPhases: PhaseEnum[] = [];
            for (let key in allHiddenModulesByPhases) {
                let services = allHiddenModulesByPhases[key];
                const filteredServices = services
                    .filter(service => !suiviConsos.find(suiviConso => suiviConso.service == service.service
                        && suiviConso.phase == service.phase)
                    );
                if (filteredServices?.length > 0) {
                    otherModules[key] = filteredServices;
                    filteredPhases.push(key as PhaseEnum);
                }
            }
            setAllServicesByPhaseFiltered(otherModules);
            setAllPhasesFiltered(filteredPhases);
            setSelectedFilter(ModulesFilterEnum.OTHER_MODULES);
        } else {
            // Display only users modules
            const servicesByPhase = _.groupBy(suiviConsos, suiviConso => suiviConso.phase) as ServicesByPhase || {};
            const phases = Object.keys(servicesByPhase) as PhaseEnum[] || [];
            setAllServicesByPhaseFiltered(servicesByPhase);
            setAllPhasesFiltered(phases);
            setSelectedFilter(ModulesFilterEnum.MY_MODULES);
        }
    }

    const onClickSuiviConso = (suivi: SuiviConsommationModel) => {
        suiviConsoService.firstClick(suivi.userID, suivi.phase, suivi.service)
            .then(async () => {
                const user = userService.getCurrentUser();
                await suiviConsoState.loadSuiviConsommationsAndParcours(user?.userID);
            })
    }

    return (<Container maxWidth="lg" className={classes.root}>
        <WelcomeMessage showSubtitle={true} subTitle={subtitle}/>
        <Grid container className={classes.row}>
            <Select
                value={selectedFilter}
                labelId="ModulesFilter"
                onChange={(event) => filterModulesToDisplay(event.target.value as ModulesFilterEnum)}
            >
                <MenuItem aria-label="None" value={ModulesFilterEnum.MY_MODULES} key="MY_MODULES">
                    {modulesFilterEnum[ModulesFilterEnum.MY_MODULES]}
                </MenuItem>
                <MenuItem aria-label="None" value={ModulesFilterEnum.OTHER_MODULES} key="OTHER_MODULES">
                    {modulesFilterEnum[ModulesFilterEnum.OTHER_MODULES]}
                </MenuItem>
            </Select>
        </Grid>

        {
            getPhasesFilteredList().map((phase: PhaseEnum, index: number) => (
                <LearnerPhaseServiceList phase={phase} key={phase}
                                         displayBackButton={!index}
                                         userPhasesAndServices={suiviConsoState.suiviConsoByPhase?.[phase] || []}
                                         filteredServicesForThisPhase={allServicesByPhaseFiltered[phase]}
                                         onClickSuiviConso={onClickSuiviConso}/>
            ))
        }
    </Container>);
}
