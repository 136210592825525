import React, {useState} from 'react';
import {useTheme} from "@material-ui/core/styles";
import {useStyles} from "./style";
import {useTranslation} from "react-i18next";

// @ts-ignore
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Snackbar,
    Typography
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import {AlertTitle} from "@material-ui/lab";
import InfoIcon from '@material-ui/icons/Info';
import {PhaseEnum, ServiceEnum} from "../../../models/catalogue.model";
import {SessionDetail} from "../../../models/session-detail";
import LearnerSessionAccordeonList from "../LearnerParcoursPhaseService/LearnerSessionAccordeonList";
import BybButton from "../../Common/BybButton";
import {RequestModuleService} from "../../../services/request-module.service";
import {UserService} from "../../../services/user.service";
import {AxiosError} from "axios";

export interface LearnerForbiddenModuleResumePopupProps {
    service: ServiceEnum;
    phase: PhaseEnum;
    nbSession: number;
    description: string;
    goal: string;
    sessionsDetails: SessionDetail[];
    open: boolean;
    handleClose: () => void
}

export default function LearnerForbiddenModuleResumePopup(props: LearnerForbiddenModuleResumePopupProps) {

    const theme = useTheme();
    const classes = useStyles(theme);
    const {t} = useTranslation();
    const requestModuleService = new RequestModuleService();
    const userService = new UserService();

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [openSnackbarSuccess, setOpenSnackbarSuccess] = useState(false);
    const [openSnackbarFailure, setOpenSnackbarFailure] = useState(false);


    const moduleTitle = (service: ServiceEnum) => {
        return t(`catalogue.services.learner.${service}`).split(':')?.[0].trim()
            || t(`catalogue.services.learner.${service}`)
    };

    const requestAdditionalModule = async () => {
        setIsSubmitting(true);
        await requestModuleService.requestAdditionalModule(
            userService.getCurrentUser().userID,
            props.phase,
            props.service
        )
            .then(() => {
                setSuccessMessage(t('catalogue.requestSucess'));
                setOpenSnackbarSuccess(true);
            })
            .catch((error: AxiosError) => {
                if (error?.response?.status === 409) {
                    setErrorMessage(t('catalogue.requestExists'));
                    setOpenSnackbarFailure(true);
                } else {
                    setErrorMessage(t('catalogue.requestFailure'));
                    setOpenSnackbarFailure(true);
                }
            }).finally(() => {
                setIsSubmitting(false);
            });
    }

    const handleCloseSnackbarSuccess = () => {
        setOpenSnackbarSuccess(false);
    }
    const handleCloseSnackbarFailure = () => {
        setOpenSnackbarFailure(false);
    }


    return (
        <>
            <Snackbar open={openSnackbarSuccess} autoHideDuration={6000} onClose={handleCloseSnackbarSuccess}>
                <Alert onClose={handleCloseSnackbarSuccess} severity="success">
                    {successMessage}
                </Alert>
            </Snackbar>
            <Snackbar open={openSnackbarFailure} autoHideDuration={6000} onClose={handleCloseSnackbarFailure}>
                <Alert onClose={handleCloseSnackbarFailure} severity="error">
                    {errorMessage}
                </Alert>
            </Snackbar>
            <Dialog
                fullWidth
                maxWidth={'md'}
                open={props.open}
                onClose={props.handleClose}
            >
                <DialogTitle id="alert-dialog-title" className={classes.title}>
                    {moduleTitle(props.service)}
                </DialogTitle>
                <DialogContent>
                    <div className={classes.description}>
                        <Typography className={classes.accordionTitle}
                                    variant="body1">{t('catalogue.presentation')}</Typography>
                        <Typography variant="body1"
                                    dangerouslySetInnerHTML={{__html: `<p>${props?.description}</p>`}}/>
                    </div>
                    <div className={classes.goal}>
                        <Alert severity="info" icon={<InfoIcon fontSize="inherit"/>}>
                            <AlertTitle>{t("catalogue.objectifs")}</AlertTitle>
                            <Typography variant="body1" dangerouslySetInnerHTML={{__html: `${props?.goal}`}}/>
                        </Alert>
                    </div>
                    <div className={classes.title}>
                        <Typography variant="h6">{t("catalogue.sectionList")}</Typography>
                    </div>
                    <Divider/>
                    <LearnerSessionAccordeonList nbSession={props.nbSession} service={props.service}
                                                 sessionsDetails={props.sessionsDetails}/>
                </DialogContent>
                <DialogActions>
                    <BybButton
                        onClick={props.handleClose}
                        classes={classes.button}
                        loading={isSubmitting}
                        label={t('close')}
                        variant="contained"
                        type="button">
                    </BybButton>
                    <BybButton
                        onClick={requestAdditionalModule}
                        classes={classes.button}
                        loading={isSubmitting}
                        label={t('catalogue.request')}
                        color="primary"
                        variant="contained"
                        type="button">
                    </BybButton>
                </DialogActions>
            </Dialog>
        </>
    );
}

