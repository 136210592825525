import {PhaseEnum, ServiceEnum} from "./catalogue.model";
import {User} from "./user.model";

export class RequestModule {

    userID!: string;

    createdDate!: Date;

    updatedDate!: Date;

    phase!: PhaseEnum;

    service!: ServiceEnum;

    status!: RequestModuleStatusEnum;

    user!: User;

}


export interface RequestModulePaginate {
    total: number;
    data: RequestModule[];
}

export enum RequestModuleStatusEnum {
    PENDING = "PENDING",
    VALIDATED = "VALIDATED",
}