import axios, {AxiosError} from "axios";
import {log} from "../utils/log";
import {UserRole} from "../models/user.model";
import {Catalogue, PhaseEnum, PhaseService, ServiceEnum} from "../models/catalogue.model";

export class CatalogueService {

    catalogueEndpoint = '/api/v1/catalogue';
    UNEXPECTED_ERROR = `Une erreur temporaire est survenue, Veuillez réessayer s'il vous plait`;

    // TODO get from parcours controller
    public async getAmount(type: UserRole): Promise<number> {
        return await axios.get(`${this.catalogueEndpoint}/amount?type=${type}`)
            .then(response => {
                return response.data.prix;
            }).catch((error: AxiosError) => {
                log.error("Error in catalogue retrieving: " + error);
                throw this.UNEXPECTED_ERROR;
            });
    }

    public async getByPhaseAndService(phase: PhaseEnum, service: ServiceEnum): Promise<Catalogue> {
        return await axios.get(`${this.catalogueEndpoint}/phase/${phase}/service/${service}`)
            .then(response => {
                return response.data;
            }).catch((error: AxiosError) => {
                log.error("Error in catalogue retrieving: " + error);
                throw error;
            });
    }

    public async getLonguestCatalogueByPhaseAndService(phase: PhaseEnum, service: ServiceEnum): Promise<Catalogue> {
        return await axios.get(`${this.catalogueEndpoint}/max/phase/${phase}/service/${service}`)
            .then(response => {
                return response.data;
            }).catch((error: AxiosError) => {
                log.error("Error in catalogue retrieving: " + error);
                throw error;
            });
    }

    public async getAllServices(): Promise<PhaseService[]> {
        return await axios.get(`${this.catalogueEndpoint}/services`)
            .then(response => {
                return response.data;
            }).catch((error: AxiosError) => {
                log.error("Error in catalogue services retrieving: " + error);
                throw error;
            });
    }

}
