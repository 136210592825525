import axios, {AxiosError} from "axios";
import {log} from "../utils/log";
import {PhaseEnum, ServiceEnum} from "../models/catalogue.model";
import {RequestModule, RequestModulePaginate} from "../models/request-module";
import {Pagination} from "../models/purchase.model";

export class RequestModuleService {

    endpoint = '/api/v1/request-module';
    public static MAX__PER_PAGE = 10;


    public async requestAdditionalModule(userID: string, phase: PhaseEnum, service: ServiceEnum): Promise<any> {
        const requestModule = {
            userID,
            phase,
            service
        } as RequestModule;
        return await axios.post(`${this.endpoint}`, requestModule)
            .then(response => {
                return response.data;
            }).catch((error: AxiosError) => {
                log.error("Error in requestAdditionalModule: " + error);
                throw error;
            });
    }

    public async getAll(page: number): Promise<RequestModulePaginate> {
        return await axios.get(`${this.endpoint}?range=[${page},${RequestModuleService.MAX__PER_PAGE}]`)
            .then(response => {
                const paginationData = response.data as Pagination<any>;
                return {total: paginationData.total, data: paginationData.results};
            }).catch((error: AxiosError) => {
                log.error("Error in requests fetching: " + error);
                throw error;
            });
    }

}
