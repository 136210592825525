import React, {useEffect, useState} from 'react';
import {useTheme} from "@material-ui/core/styles";
import {useStyles} from "./style";
import {useTranslation} from "react-i18next";
// @ts-ignore
import {
    Avatar,
    Button,
    CircularProgress,
    Container,
    Divider,
    Grid,
    MobileStepper,
    Paper,
    Step,
    StepLabel,
    Stepper,
    Tooltip,
    Typography
} from "@material-ui/core";
import {SuiviConsommationContainer} from "../../../../container/SuiviConsommationContainer";
import {SuiviConsommationModel} from "../../../../models/suivi-consommation.model";
import {PhaseEnum, ServiceEnum} from "../../../../models/catalogue.model";
import {CoachSummaryModel} from "../../../../models/coach-summary.model";
import {CoachModuleService} from "../../../../services/coach-module.service";
import {KeyboardArrowLeft, KeyboardArrowRight} from "@material-ui/icons";
import LearnerSessionHome from "../../LearnerParcoursPhaseService/LearnerSessionHome";
import CoachResumePopup from "../../../Coach/CoachResumePopup";
import {MeetingConfirmationService} from "../../../../services/meeting-confirmation.service";
import {MeetingModel} from "../../../../models/meeting.model";
import BybButton from "../../../Common/BybButton";
import LearnerSessionWrapper from "../../LearnerParcoursPhaseService/LearnerSessionWrapper";
import {ModuleDetails} from "../../../../models/module-details";
import {ModuleDetailsService} from "../../../../services/module-details.service";
import Alert from "@material-ui/lab/Alert";
import {SessionDetailsService} from "../../../../services/session-details.service";
import {SessionDetail} from "../../../../models/session-detail";
import {sessionDetails, sessionDetailsCoach} from "../../../../utils/sessionDetails.util";
import {SessionDetailsCoachService} from "../../../../services/session-details-coach.service";
import {UserService} from "../../../../services/user.service";
import {SessionDetailCoachModel} from "../../../../models/session-detail-coach.model";
import PageTitle from "../../../Common/PageTitle";

function _renderStepContent(step: number, sessionDone: boolean, nbSessions: number, phase: PhaseEnum, service: ServiceEnum,
                            description: string = '', goal: string = '', sessionsDetails: SessionDetail[] = [],
                            handleNext: () => void, sessionsDetailsCoach: SessionDetailCoachModel[] = []) {
    if (step === 0) {
        return <LearnerSessionHome nbSession={nbSessions} phase={phase}
                                   service={service} handleNext={handleNext}
                                   sessionsDetails={sessionsDetails}
                                   description={description} goal={goal}/>;
    } else if (step > 0) {
        /*Session*/
        return (<>
            {/*
            <ComponentToPdf fileName={`${phase}_${service}_${step}.pdf`}/>
*/}
            <LearnerSessionWrapper phase={phase}
                                   service={service}
                                   numeroSession={step}
                                   sessionDetails={sessionDetails(sessionsDetails, step)}
                                   sessionsDetailsCoach={sessionDetailsCoach(sessionsDetailsCoach, step)}
                                   sessionDone={sessionDone}/>
        </>);
    }
}

export interface LearnerSessionStepperProps {
    phase: PhaseEnum;
    service: ServiceEnum;
}

export default function LearnerSessionStepper(props: LearnerSessionStepperProps) {

    const theme = useTheme();
    const classes = useStyles(theme);
    const {t} = useTranslation();

    const [activeStep, setActiveStep] = React.useState(0);
    const [errorMessage, setErrorMessage] = useState("");
    const [notAuthorized, setNotAuthorized] = useState(true);
    const [coachSummary, setCoachSummary] = useState<CoachSummaryModel | null>(null);
    const [open, setOpen] = React.useState(false);
    const [loadingNext, setLoadingNext] = React.useState(false);
    const [sessionDone, setSessionDone] = useState(false);
    const [nbSessions, setNbSession] = useState(0);
    const [moduleDetails, setModuleDetails] = useState<ModuleDetails>();
    const [sessionsDetails, setSessionsDetails] = useState<SessionDetail[]>();
    const [sessionsDetailsCoach, setSessionsDetailsCoach] = useState<SessionDetailCoachModel[] | null>(null);

    const moduleDetailsService = new ModuleDetailsService();
    const sessionDetailsService = new SessionDetailsService();
    const suiviConsoState = SuiviConsommationContainer.useContainer();
    const me = new UserService().getCurrentUser();

    const getCoachName = (coachSummary: CoachSummaryModel) => {
        const firstName = coachSummary?.user?.firstname || '';
        const lastname = coachSummary?.user?.lastname || '';
        return firstName + ' ' + lastname;
    }

    const showCoachResume = () => {
        setOpen(true);
    }

    const labelStepButton = activeStep === 0 ? t('catalogue.start') : t('catalogue.next');
    const iconStepButton = <KeyboardArrowRight/>;

    useEffect(() => {
        const auth = suiviConsoState.suiviConso?.find((suiviconso: SuiviConsommationModel) => suiviconso.phase === props.phase
            && suiviconso.service === props.service);
        setNotAuthorized(auth === undefined);
        setNbSession(auth?.heuresTotal || 0);
    }, [suiviConsoState.suiviConso, props.phase, props.service]);

    useEffect(() => {
        if (notAuthorized) {
            setErrorMessage(t('notAuthorized'));
        } else {
            setErrorMessage('');
        }
    }, [notAuthorized]);

    useEffect(() => {
        (async () => {
            new CoachModuleService().getCoachSummaryByPhaseAndService(props.phase, props.service).then((res) => {
                setCoachSummary(res);
            })
        })()
    }, [props.phase, props.service]);

    useEffect(() => {
        (async () => {
            setLoadingNext(true);
            if (activeStep > 0) {
                new MeetingConfirmationService().getMeetingConfirmation(props.phase, props.service, activeStep)
                    .then((meetingModel: MeetingModel) => {
                        if (meetingModel.meetingConfirmationID) {
                            setSessionDone(meetingModel?.confirm !== null && meetingModel?.confirm !== undefined);
                        } else {
                            setSessionDone(false);
                        }
                        setLoadingNext(false);
                    }).catch(() => {
                    setSessionDone(false);
                    // setErrorMessage(t('unexpectedErrorLoading'));
                });
            } else {
                setLoadingNext(false);
                setSessionDone(true);
            }
        })()
    }, [activeStep, props.phase, props.service]);


    useEffect(() => {
        (() => {
            moduleDetailsService.getOneByPhaseAndService(props.phase, props.service)
                .then((moduleDetails: ModuleDetails) => {
                    setModuleDetails(moduleDetails)
                })
                .catch(() => {
                    setErrorMessage(t('unexpectedErrorLoading'));
                });
        })();
    }, [props.service]);

    useEffect(() => {
        (() => {
            if (moduleDetails) {
                sessionDetailsService.getByModule(moduleDetails.moduleDetailsID)
                    .then((sessions: SessionDetail[]) => {
                        setSessionsDetails(sessions);
                    })
                    .catch(() => {
                        setErrorMessage(t('unexpectedErrorLoading'));
                    });
            }
        })();
    }, [moduleDetails]);

    useEffect(() => {
        (() => {
            if (props.phase && props.service && coachSummary?.user) {
                new SessionDetailsCoachService().getListByModuleAndCoachIDAndLearner(props.phase, props.service, coachSummary?.user.userID, me.userID)
                    .then(data => {
                        setSessionsDetailsCoach(data);
                    }).catch(() => {
                    setErrorMessage(t('unexpectedErrorLoading'));
                });
            }
        })();
    }, [props.phase, props.service, coachSummary?.user]);

    async function handleNext() {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleClosePopup = () => {
        setOpen(false);
    };


    return (
        <Container maxWidth="lg" className={classes.root} id={"pdf"}>
            {errorMessage && <Alert severity="error" className={classes.row}>{errorMessage}</Alert>}
            {!notAuthorized &&
                <Grid container className={classes.row} direction="row"
                      justify={'center'} alignItems={'stretch'}>
                    <CoachResumePopup coachSummary={coachSummary} open={open} handleClose={handleClosePopup}/>
                    <PageTitle title={'Module: ' + moduleDetails?.title} goToParcours={true}/>
                    <Paper square className={classes.paper} elevation={1}>
                        <div className={classes.content}>
                            <MobileStepper
                                variant="dots"
                                steps={nbSessions + 1}
                                position="static"
                                activeStep={activeStep}
                                className={classes.stepper}
                                nextButton={
                                    <BybButton size="small" onClick={handleNext}
                                               disabled={activeStep === nbSessions || !sessionDone}
                                               loading={loadingNext}
                                               label={labelStepButton}>
                                        {iconStepButton}
                                    </BybButton>
                                }
                                backButton={
                                    <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                                        {theme.direction === 'rtl' ? <KeyboardArrowRight/> : <KeyboardArrowLeft/>}
                                        {t('catalogue.previous')}
                                    </Button>
                                }
                            />


                            {
                                _renderStepContent(activeStep, sessionDone, nbSessions, props.phase, props.service,
                                    moduleDetails?.description, moduleDetails?.goal, sessionsDetails, handleNext, sessionsDetailsCoach || [])
                            }
                        </div>
                    </Paper>

                    <div className={classes.coachResume}>
                        <div className={classes.coachTitle}>
                            <Typography className={classes.accordionTitle} variant="h6">
                                {t("catalogue.lecoach")}:
                            </Typography>
                        </div>
                        {coachSummary && <div className={classes.avatar}>
                            <Avatar
                                variant="square"
                                className={classes.coachImage}
                                alt={coachSummary?.user?.email}
                                src={coachSummary?.user?.avatar || ''}/>
                            <div>
                                <Typography className={classes.accordionTitle} variant="subtitle1">
                                    {getCoachName(coachSummary)}
                                </Typography>
                                <Button size="small" onClick={() => showCoachResume()} variant="outlined"
                                        color="primary">
                                    {t('catalogue.coachResumeButton')}
                                </Button>
                            </div>
                        </div>}
                    </div>
                    <div className={classes.wizardButtons}>
                        <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                            {theme.direction === 'rtl' ? <KeyboardArrowRight/> : <KeyboardArrowLeft/>}
                            {t('catalogue.previous')}
                        </Button>
                        <BybButton size="medium" onClick={handleNext}
                                   disabled={activeStep === nbSessions || !sessionDone}
                                   loading={loadingNext}
                                   label={labelStepButton}>
                            {iconStepButton}
                        </BybButton>
                    </div>
                </Grid>}
        </Container>


    );
}

