import React from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import SignIn from './pages/SignIn';
import DashboardLearner from './pages/DashboardLearner';
import ProtectedRoute, {defaultProtectedRouteProps} from "./components/Common/Auth";
import FirstPasswordChange from "./pages/FirstPasswordChange";
import SignUp from "./pages/SignUp";
import SignUpEnd from "./pages/SignUpEnd";
import DashboardEnterprise from "./pages/DashboardEnterprise";
import {Navigate} from 'react-router';
import DashboardEnterpriseContent from "./components/Enterprise/DashboardEnterpriseContent";
import EnterpriseAddUser from "./components/Enterprise/EnterpriseAddUser";
import EnterprisePurchase from "./components/Enterprise/EnterprisePurchase";
import EnterpriseProfile from "./components/Enterprise/EnterpriseProfile";
import EntrepriseUsers from "./components/Enterprise/EntrepriseUsers";
import DashboardLearnerContent from "./components/Learner/DashboardLearnerContent";
import UserProfile from "./components/Common/UserProfile";
import LearnerAvancement from "./components/Learner/LearnerAvancement";
import {SuiviConsommationContainer} from "./container/SuiviConsommationContainer";
import LearnerPhaseServiceListWrapper from "./components/Learner/LearnerPhaseServiceListWrapper";
import {MySettings} from "./components/Common/MySettings";
import {SettingsContainer} from './container/SettingsContainer';
import BilanPro from "./components/Learner/LearnerParcours/BilanParcoursPro/BilanPro";
import {BilanProContainer} from './container/BilanProContainer';
import DashboardCoach from "./pages/DashboardCoach";
import DashboardCoachContent from "./components/Coach/DashboardCoachContent";
import CoachResume from "./components/Coach/CoachResume";
import {CoachResumeContainer} from './container/CoachResumeContainer';
import {UserRole} from "./models/user.model";
import WellBeing from "./components/Learner/LearnerParcours/WellBeing";
import {UserDocumentsContainer} from "./container/UserDocumentContainer";
import LearnerDocuments from "./components/Learner/LearnerDocuments";
import CoachLearners from "./components/Coach/CoachLearners";
import {CoachLearnerDetailsContainer} from "./container/CoachLearnerDetailsContainer";
import CoachLearnerDetails from "./components/Coach/CoachLearnerDetails";
import CoachLearnerConfirmSession from "./components/Coach/CoachLearnerConfirmSession";
import LearnerConfirmSession from "./components/Learner/LearnerConfirmSession";
import CoachPaiements from "./components/Coach/CoachPaiements";
import {CoachStripeAccountContainer} from "./container/CoachStripeAccountContainer";
import BilanProGuidelines from "./components/Learner/LearnerParcours/BilanParcoursPro/BilanProGuidelines";
import BilanProBooking from "./components/Learner/LearnerParcours/BilanParcoursPro/BilanProBooking";
import Networking from "./components/Learner/LearnerParcours/Networking";
import DigitalImage from "./components/Learner/LearnerParcours/DigitalImage";
import CoachPendingPaiements from "./components/Coach/CoachPendingPaiements";
import LearnerCoachList from "./components/Learner/LearnerCoachList";
import {NotificationContainer} from "./container/NotificationContainer";
import Resilience from "./components/Learner/LearnerParcours/Resilience";
import SelfConfiance from "./components/Learner/LearnerParcours/SelfConfiance";
import UnderstandMyPersonality from "./components/Learner/LearnerParcours/UnderstandMyPersonality";
import Communication from "./components/Learner/LearnerParcours/Communication";
import ContactUs from "./pages/ContactUs";
import Pitch from "./components/Learner/LearnerParcours/Pitch";
import CoachLearnerDetailsBilanPro from "./components/Coach/CoachLearnerDetails/CoachLearnerDetailsBilanPro";
import CoachLearnerDetailsModule from "./components/Coach/CoachLearnerDetails/CoachLearnerDetailsModule";
import CV from "./components/Learner/LearnerParcours/CV";
import Diversity from "./components/Learner/LearnerParcours/Diversity";
import TrainingAndDebrief from "./components/Learner/LearnerParcours/TrainingAndDebrief";
import CoachModules from "./components/Coach/CoachModules";
import CoachSessionStepper from "./components/Coach/CoachModuleDetails/CoachSessionStepper";
import Vision360 from "./components/Learner/LearnerParcours/360View";
import Rayonner from "./components/Learner/LearnerParcours/Shine";
import LearnerRequests from "./components/Learner/LearnerRequests";
import EnterpriseLearnersRequests from "./components/Enterprise/EnterpriseLearnersRequests";


const AppRouter: React.FC = () => {


    return (


        <BrowserRouter>
            <Routes>
                <Route path="/">
                    <SignIn/>
                </Route>
                <Route path="/signin">
                    <SignIn/>
                </Route>
                <Route path="/signup">
                    <SignUp/>
                </Route>
                <Route path="/verify-account">
                    <SignUpEnd/>
                </Route>
                <Route path="/forgot-password">
                    <ForgotPassword/>
                </Route>
                <Route path="/reset-password">
                    <ResetPassword/>
                </Route>
                <Route path="/first-password">
                    <FirstPasswordChange/>
                </Route>
                <ProtectedRoute path="/contactUs" {...defaultProtectedRouteProps}>
                    <ContactUs/>
                </ProtectedRoute>
                {/*
                Learner routes
                */}
                <ProtectedRoute {...defaultProtectedRouteProps} role={UserRole.ENTREPRISE_CADRE}
                                path='learner' element={
                    <SettingsContainer.Provider>
                        <SuiviConsommationContainer.Provider>
                            <UserDocumentsContainer.Provider>
                                <CoachStripeAccountContainer.Provider>
                                    <NotificationContainer.Provider>
                                        <DashboardLearner/>
                                    </NotificationContainer.Provider>
                                </CoachStripeAccountContainer.Provider>
                            </UserDocumentsContainer.Provider>
                        </SuiviConsommationContainer.Provider>
                    </SettingsContainer.Provider>
                }>
                    <Route path="/">
                        <Navigate to="/learner/dashboard"/>
                    </Route>
                    <ProtectedRoute path="/dashboard" {...defaultProtectedRouteProps}>
                        <DashboardLearnerContent/>
                    </ProtectedRoute>
                    <ProtectedRoute path="/profile" {...defaultProtectedRouteProps}>
                        <UserProfile/>
                    </ProtectedRoute>
                    <ProtectedRoute path="/documents" {...defaultProtectedRouteProps}>
                        <SuiviConsommationContainer.Provider>
                            <LearnerDocuments/>
                        </SuiviConsommationContainer.Provider>
                    </ProtectedRoute>
                    <ProtectedRoute path="/progression" {...defaultProtectedRouteProps}>
                        <LearnerAvancement/>
                    </ProtectedRoute>
                    <ProtectedRoute path="/parcours" {...defaultProtectedRouteProps}>
                        <LearnerPhaseServiceListWrapper/>
                    </ProtectedRoute>
                    <ProtectedRoute path="/settings" {...defaultProtectedRouteProps}>
                        <MySettings/>
                    </ProtectedRoute>
                    <ProtectedRoute path="/coachs" {...defaultProtectedRouteProps}>
                        <LearnerCoachList/>
                    </ProtectedRoute>
                    <ProtectedRoute path="/requests" {...defaultProtectedRouteProps}>
                        <LearnerRequests/>
                    </ProtectedRoute>
                    <ProtectedRoute path="/bilanpro/guidelines" {...defaultProtectedRouteProps}>
                        <BilanProContainer.Provider>
                            <BilanProGuidelines/>
                        </BilanProContainer.Provider>
                    </ProtectedRoute>
                    <ProtectedRoute path="/bilanpro/booking" {...defaultProtectedRouteProps}>
                        <BilanProContainer.Provider>
                            <BilanProBooking/>
                        </BilanProContainer.Provider>
                    </ProtectedRoute>
                    <ProtectedRoute path="/bilanpro" {...defaultProtectedRouteProps}>
                        <BilanProContainer.Provider>
                            <BilanPro/>
                        </BilanProContainer.Provider>
                    </ProtectedRoute>
                    <ProtectedRoute path="/wellbeing" {...defaultProtectedRouteProps}>
                        <WellBeing/>
                    </ProtectedRoute>
                    <ProtectedRoute path="/resilience" {...defaultProtectedRouteProps}>
                        <Resilience/>
                    </ProtectedRoute>
                    <ProtectedRoute path="/selfConfiance" {...defaultProtectedRouteProps}>
                        <SelfConfiance/>
                    </ProtectedRoute>
                    <ProtectedRoute path="/understandPersonality" {...defaultProtectedRouteProps}>
                        <UnderstandMyPersonality/>
                    </ProtectedRoute>
                    <ProtectedRoute path="/communication" {...defaultProtectedRouteProps}>
                        <Communication/>
                    </ProtectedRoute>
                    <ProtectedRoute path="/notifications" {...defaultProtectedRouteProps}>
                        <LearnerConfirmSession/>
                    </ProtectedRoute>
                    <ProtectedRoute path="/networking" {...defaultProtectedRouteProps}>
                        <Networking/>
                    </ProtectedRoute>
                    <ProtectedRoute path="/digitalImage" {...defaultProtectedRouteProps}>
                        <DigitalImage/>
                    </ProtectedRoute>
                    <ProtectedRoute path="/pitch" {...defaultProtectedRouteProps}>
                        <Pitch/>
                    </ProtectedRoute>
                    <ProtectedRoute path="/cv" {...defaultProtectedRouteProps}>
                        <CV/>
                    </ProtectedRoute>
                    <ProtectedRoute path="/diversity" {...defaultProtectedRouteProps}>
                        <Diversity/>
                    </ProtectedRoute>
                    <ProtectedRoute path="/trainingAndDebrief" {...defaultProtectedRouteProps}>
                        <TrainingAndDebrief/>
                    </ProtectedRoute>
                    <ProtectedRoute path="/360View" {...defaultProtectedRouteProps}>
                        <Vision360/>
                    </ProtectedRoute>
                    <ProtectedRoute path="/shine" {...defaultProtectedRouteProps}>
                        <Rayonner/>
                    </ProtectedRoute>
                </ProtectedRoute>
                {/*
                Enterprise routes
                */}
                <ProtectedRoute {...defaultProtectedRouteProps} role={UserRole.ENTREPRISE}
                                path='enterprise' element={
                    <CoachStripeAccountContainer.Provider>
                        <NotificationContainer.Provider>
                            <DashboardEnterprise/>
                        </NotificationContainer.Provider>
                    </CoachStripeAccountContainer.Provider>}>
                    <Route path="/">
                        <Navigate to="/enterprise/dashboard"/>
                    </Route>
                    <ProtectedRoute path="/dashboard" {...defaultProtectedRouteProps}>
                        <DashboardEnterpriseContent/>
                    </ProtectedRoute>
                    <ProtectedRoute path="/addUser" {...defaultProtectedRouteProps}>
                        <EnterpriseAddUser/>
                    </ProtectedRoute>
                    <ProtectedRoute path="/purchases" {...defaultProtectedRouteProps}>
                        <EnterprisePurchase/>
                    </ProtectedRoute>
                    <ProtectedRoute path="/profile" {...defaultProtectedRouteProps}>
                        <EnterpriseProfile/>
                    </ProtectedRoute>
                    <ProtectedRoute path="/users" {...defaultProtectedRouteProps}>
                        <EntrepriseUsers/>
                    </ProtectedRoute>
                    <ProtectedRoute path="/users" {...defaultProtectedRouteProps}>
                        <EntrepriseUsers/>
                    </ProtectedRoute>
                    <ProtectedRoute path="/notifications" {...defaultProtectedRouteProps}>
                        <EnterpriseLearnersRequests/>
                    </ProtectedRoute>
                </ProtectedRoute>
                {/*
                COACH routes
                */}
                <ProtectedRoute {...defaultProtectedRouteProps}
                                path='coach' element={
                    <CoachStripeAccountContainer.Provider>
                        <DashboardCoach/>
                    </CoachStripeAccountContainer.Provider>} role={UserRole.COACH}>
                    <Route path="/">
                        <Navigate to="/coach/dashboard"/>
                    </Route>
                    <ProtectedRoute path="/dashboard" {...defaultProtectedRouteProps}>
                        <DashboardCoachContent/>
                    </ProtectedRoute>
                    <ProtectedRoute path="/profile" {...defaultProtectedRouteProps}>
                        <UserProfile/>
                    </ProtectedRoute>
                    <ProtectedRoute path="/payment" {...defaultProtectedRouteProps}>
                        <CoachPaiements/>
                    </ProtectedRoute>
                    <ProtectedRoute path="/pendingConfirmation" {...defaultProtectedRouteProps}>
                        <CoachPendingPaiements/>
                    </ProtectedRoute>
                    <ProtectedRoute path="/resume" {...defaultProtectedRouteProps}>
                        <CoachResumeContainer.Provider>
                            <CoachResume/>
                        </CoachResumeContainer.Provider>
                    </ProtectedRoute>
                    <ProtectedRoute path="/learners" {...defaultProtectedRouteProps}>
                        <CoachResumeContainer.Provider>
                            <CoachLearnerDetailsContainer.Provider>
                                <CoachLearners/>
                            </CoachLearnerDetailsContainer.Provider>
                        </CoachResumeContainer.Provider>
                    </ProtectedRoute>
                    <ProtectedRoute path="/learner/details" {...defaultProtectedRouteProps}>
                        <CoachLearnerDetailsContainer.Provider>
                            <UserDocumentsContainer.Provider>
                                <CoachResumeContainer.Provider>
                                    <CoachLearnerDetails/>
                                </CoachResumeContainer.Provider>
                            </UserDocumentsContainer.Provider>
                        </CoachLearnerDetailsContainer.Provider>
                    </ProtectedRoute>
                    <ProtectedRoute path="/modules" {...defaultProtectedRouteProps}>
                        <CoachResumeContainer.Provider>
                            <CoachModules/>
                        </CoachResumeContainer.Provider>
                    </ProtectedRoute>
                    <ProtectedRoute path="/module/details" {...defaultProtectedRouteProps}>
                        <CoachResumeContainer.Provider>
                            <CoachSessionStepper/>
                        </CoachResumeContainer.Provider>
                    </ProtectedRoute>
                    <ProtectedRoute path="/learner/details/bilanPro" {...defaultProtectedRouteProps}>
                        <CoachLearnerDetailsContainer.Provider>
                            <UserDocumentsContainer.Provider>
                                <CoachLearnerDetailsBilanPro/>
                            </UserDocumentsContainer.Provider>
                        </CoachLearnerDetailsContainer.Provider>
                    </ProtectedRoute>
                    <ProtectedRoute path="/learner/details/module" {...defaultProtectedRouteProps}>
                        <UserDocumentsContainer.Provider>
                            <CoachLearnerDetailsModule/>
                        </UserDocumentsContainer.Provider>
                    </ProtectedRoute>
                    <ProtectedRoute path="/learner/confirmSession" {...defaultProtectedRouteProps}>
                        <CoachResumeContainer.Provider>
                            <CoachLearnerDetailsContainer.Provider>
                                <CoachLearnerConfirmSession/>
                            </CoachLearnerDetailsContainer.Provider>
                        </CoachResumeContainer.Provider>
                    </ProtectedRoute>

                </ProtectedRoute>
            </Routes>
        </BrowserRouter>
    )
};

export default AppRouter;
