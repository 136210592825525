import _ from "lodash";
import {useState} from "react";
import {createContainer} from "unstated-next";
import {ServicesByPhase, SuiviConsommationModel, SuiviConsommationsByPhase} from "../models/suivi-consommation.model";
import {SuiviConsommationService} from "../services/suivi-consommation.service";
import {ParcoursService} from "../services/parcours.service";
import {UserService} from "../services/user.service";
import {ParcoursModel} from "../models/parcours.model";
import {CatalogueService} from "../services/catalogue.service";
import {PhaseEnum} from "../models/catalogue.model";
import {EnterpriseService} from "../services/enterprise.service";


function SuiviConsommationContainerHook() {
    const suiviConsoService = new SuiviConsommationService();
    const parcoursService = new ParcoursService();
    const userService = new UserService();
    const catalogueService = new CatalogueService();
    const enterpriseService = new EnterpriseService();

    let [suiviConso, setSuiviConso] = useState<SuiviConsommationModel[]>([]);
    let [suiviConsoByPhase, setSuiviConsoByPhase] = useState<SuiviConsommationsByPhase>({});
    let [userParcours, setUserParcours] = useState<ParcoursModel | null>(null);
    let [allPhases, setAllPhases] = useState<PhaseEnum[] | null>(null);
    let [allServicesByPhase, setAllServicesByPhase] = useState<ServicesByPhase | null>(null);
    let [hiddenModulesByPhase, setHiddenModulesByPhase] = useState<ServicesByPhase | null>(null);
    let [hiddenPhases, setHiddenPhases] = useState<PhaseEnum[] | null>(null);

    let loadSuiviConsommationsAndParcours = async (userID: string) => {
        const consos = await suiviConsoService.getSuiviConsommations(userID);
        setSuiviConso(consos);
        const groupedByPhases = _.groupBy(consos, conso => conso.phase);
        setSuiviConsoByPhase(groupedByPhases);
        // Parcours
        const user = userService.getCurrentUser();
        const parcours = await parcoursService.getParcours(consos?.[0].parcoursID, UserService.isEnterpriseCadreUser(user));
        setUserParcours(parcours);
    };

    let loadAllServices = async () => {
        const allServices = await catalogueService.getAllServices();
        const groupedByPhases = _.groupBy(allServices, phaseService => phaseService.phase);
        setAllServicesByPhase(groupedByPhases);
        const allPhases = Object.keys(groupedByPhases) || [];
        setAllPhases(allPhases as PhaseEnum[]);
    }
    let loadHiddenModules = async () => {
        const hiddenModules = await enterpriseService.getHiddenModules();
        const groupedByPhases = _.groupBy(hiddenModules, phaseService => phaseService.phase);
        setHiddenModulesByPhase(groupedByPhases);
        const allPhases = Object.keys(groupedByPhases) || [];
        setHiddenPhases(allPhases as PhaseEnum[]);
    }


    return {
        suiviConso,
        suiviConsoByPhase,
        loadSuiviConsommationsAndParcours,
        userParcours,
        loadHiddenModules,
        hiddenModulesByPhase,
        hiddenPhases
    }
}

export const SuiviConsommationContainer = createContainer(SuiviConsommationContainerHook)

