import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";


export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        row: {
            marginBottom: theme.spacing(2)
        },
        phases: {
            color: '#311b92'
        },
        column: {
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'stretch',
            alignContent: 'stretch',
            minheight: '100%',
            maxheight: '100%',
            transition: 'all .2s ease-in-out',
            '&:hover': {
                transform: 'scale(1.1)'
            },
        },
        content: {
            marginTop: theme.spacing(2)
        },
        rowProgress: {
            border: 0
        },
        paper: {
            display: 'flex',
            flexDirection: 'column',
            alignContent: 'stretch',
            justifyContent: 'flex-start',
            padding: theme.spacing(2),
            textAlign: 'left',
            color: theme.palette.text.primary,
            backgroundColor: '#FFFFFF',
            width: '100%',
            '&:hover': {
                background: '#ede7f6'
            },
            '&.Mui-disabled': {
                cursor: 'not-allowed',
                pointerEvents: 'auto'
            }
        },
        consoTitle: {
            fontWeight: 0,
            textAlign: 'center'
        },
        titleAndLogo: {
            display: 'flex',
            flexDirection: 'row'
        },
        table: {
            border: '1px solid #e0e0e0'
        },
        subtitle2: {
            fontWeight: 'bold',
        },
        caption: {
            marginTop: theme.spacing(2),
            display: 'block',
            textAlign: 'left',
            '&:first-letter': {
                textTransform: 'capitalize',
            }
        },
        disabledButton: {
            pointerEvents: 'auto',
            backgroundColor: '#DCDCDC'
        },
        dialog: {
            display: 'flex',
            height: 'calc(100vh - 20vh)',
            [theme.breakpoints.down('sm')]: {
                height: 'calc(90vh)',
            },
        },
        closeIcon: {
            display: 'flex',
            justifyContent: 'flex-end'
        },
        pulse: {
            animation: '$pulseAnimation 2s infinite',
        },
        '@keyframes pulseAnimation': {
            '0%': {
                boxShadow: `0 0 0 0px ${theme.palette.primary.main}`,
            },
            '100%': {
                boxShadow: `0 0 0 5px ${theme.palette.primary.main}`,
            }
        }
    })
);